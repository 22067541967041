import React, { useState, useEffect } from 'react';
import { navigate } from 'gatsby';
import moment from 'moment';
import { FileBinaryIcon } from '@primer/octicons-react';

import LayoutPortal from '../../components/layout-portal';
import Seo from '../../components/seo';
import CustomFetch from '../../components/fetch';
import CustomAlert from '../../components/alert';

export default function DocumentsPage({ location }) {
    const [documents, setDocuments] = useState([]);
    const [alertState, setAlertState] = useState({
        alertClass: 'dialog success',
        alertId: 'dialogWrap',
        alertText: '',
        show: false,
    });

    const alertStateChange = (data) => {
        setAlertState({
            show: data.show,
            alertText: data.alertText,
            alertClass: data.alertClass,
        });
    };

    const handleDownload = (id) => {
        CustomFetch(
            `${process.env.GATSBY_API_URL}/documents/download/${id}`,
            'POST',
            {},
            function (result) {
                if (result.url !== '') {
                    window.open(result.url, '_blank');
                }
            },
            function (error) {
                alertStateChange({
                    show: true,
                    alertClass: 'dialog error',
                    alertText: String(error),
                });
            }
        );
    };

    useEffect(() => {
        CustomFetch(
            `${process.env.GATSBY_API_URL}/documents/table`,
            'POST',
            {
                where: [
                    {
                        id: 'api',
                        value: true,
                    },
                ],
            },
            function (result) {
                setDocuments(result.data);
            },
            function (error) {
                alertStateChange({
                    show: true,
                    alertClass: 'dialog error',
                    alertText: String(error),
                });
            }
        );

        if (
            location &&
            location.state &&
            location.state.alert &&
            location.state.alert === true
        ) {
            alertStateChange({
                show: true,
                alertClass: 'dialog ' + location.state.alertType,
                alertText: String(location.state.alertMessage),
            });
        }
    }, []);

    return (
        <LayoutPortal>
            <Seo title="Documents" description="Client Portal - Documents" />
            <div className="container container--portal">
                <div className="grid">
                    <div className="col-lg-12">
                        <div className="pitem">
                            <div className="plist">
                                <div className="plist__header">Documents</div>
                                <div className="plist__content">
                                    <ul>
                                        {documents.length > 0
                                            ? documents.map((a) => (
                                                  <li>
                                                      <button
                                                          onClick={() => {
                                                              navigate(
                                                                  '/portal/document',
                                                                  {
                                                                      state: {
                                                                          document_id:
                                                                              a.id,
                                                                      },
                                                                  }
                                                              );
                                                          }}
                                                      >
                                                          <FileBinaryIcon
                                                              size={24}
                                                          />
                                                          {a.label} [
                                                          {a.created_at
                                                              ? moment(
                                                                    a.created_at
                                                                ).format(
                                                                    'DD-MM-YYYY'
                                                                )
                                                              : null}
                                                          ]
                                                          <small>
                                                              <strong>
                                                                  {a.status ===
                                                                  0
                                                                      ? ' - Pending'
                                                                      : a.status ===
                                                                        1
                                                                      ? ' - Accepted'
                                                                      : ' - Declined'}
                                                              </strong>
                                                          </small>
                                                          <span>View</span>
                                                      </button>
                                                  </li>
                                              ))
                                            : null}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <CustomAlert alertState={alertState} />
        </LayoutPortal>
    );
}
